import React, {useEffect, useState, useContext} from "react";
import { Outlet, Link, useLocation, useNavigate  , useParams} from "react-router-dom";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { FaRegCheckCircle, FaRegTimesCircle,} from "react-icons/fa";
import { BiChevronRight} from "react-icons/bi";
// import ReactImageMagnify from 'react-image-magnify';
// import Productslider from '../../components/Slider/productSlider';
import ProductSliderRelated from "../../components/Slider/productSliderRelated";
import AddWishListContext from '../context/AddWishList';
import Button from 'react-bootstrap/Button';
import axios from "axios";
import Loader from "../../components/Slider/loader";
import Cookies from "js-cookie";
import { decrpt } from "../../components/Slider/encryption";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddToWishListBtn from '../../components/commonButton/addToWishListBtn';
import CountWishlistCartContext from '../context/CountWishListCart';
import AuthUser from "../context/AuthUser";
import Modal from 'react-bootstrap/Modal';
import AddToCartBtn from '../../components/commonButton/addToCartBtn';
import ReactHtmlParser from 'react-html-parser';
import SliderImage from 'react-zoom-slider';
import { Helmet } from "react-helmet";



function ProductDetail() {
  let { id , name } = useParams();
  console.log("pollabtesttt",id);
  console.log(name);
  const navigate = useNavigate()
  // const Wishlist = useContext(AddWishListContext);
  const Auth = useContext(AuthUser); 
  const [loader , setLoader ] = useState();
  const [size,setSize] = useState('');
  const [sizeWisePrice , setSizeWisePrice] = useState();
  const [sizeId,setSizeId] = useState('');
  const [ productDetails , setProductDetails ] = useState();
  console.log(sizeWisePrice,'sizeWisePrice');
  const location = useLocation();
  // const itemID = location.state.name;
const itemID = id;
  console.log(itemID);
  console.log(location.state , 'location');
  const[wishListData,setWishlistData]=useState();
  const [quantity,setQuantity] = useState(1);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const [showSelectSize, setShowSelectSize] = useState(false);
  const SelectSizeClose = () => setShow(false);
  // const SelectSizeShow = () => setShow(true);

  const [newAddedData, setNewAddedData]=useState();
  const [done, setDone] = useState(true);
  const [relatedProduct , setRelatedProduct ] = useState();
  // console.log(done && done , 'done');
  const [slider, setSlider] = useState([]);
  const [imgLoader , setImgLoader] = useState();

 console.log(location && location.pathname + 'djisajdi' , 'location cvheck')


  const sizeType = productDetails && productDetails.size_type;

  const Count = useContext(CountWishlistCartContext);


  const sizeCheck=()=>{
    if(productDetails && productDetails.size_type==2){
       setDone(false)
    }else{
     setDone(true)
    }
  }


  console.log(Auth && Auth,'productDetails.size_type');

  const productQuantityChange = async (type) => {
    console.log('Quantity',quantity)
    if (type == 1) {
        let quantityValue = parseInt(quantity) - 1;
        if (quantityValue > 0) {
            setQuantity(quantityValue.toString());
        }
    } else 
            {
              if (quantity < productDetails.stock) {
                  let quantityValue = parseInt(quantity) - 1;
                  if (quantityValue > 0) {
                      setQuantity(quantityValue.toString());
                  }
              }else{
                setShow(true)
              }
          }
        }



 
 let discount = Math.floor(100 * ((productDetails && productDetails.mrp) - (productDetails && productDetails.price))/(productDetails && productDetails.mrp));
  console.log(wishListData , 'wishListData check data');



  const productDetailsfetch = async(itemID) => {
    setLoader(true);
    try{
      const response = await axios.post(`/user/product/details` , {product_id: itemID})
      .then((response) => {
        console.log(response.data,'details ds');
        setProductDetails(response.data.data);
        let image = [];
        console.log(image,'lenght')
        if (response.data.data.images && response.data.data.images.length > 0) {
          
          response.data.data && response.data.data.images.map((slider) => {
            image.push(slider && slider);
          })
           setSlider(image && image)
           setImgLoader(false)
        }
        setSize(response.data.data.stock_details);
        setWishlistData(response.data.wishlist);
        setRelatedProduct(response.data.related_products);
        // setSizeWisePrice(response.data.data.stock)
        // sizeCheck();
        setLoader(false);
      })
    }catch(error){
      setLoader(true);
      console.log(error , 'error');
    }
  }

  console.log("11111111",relatedProduct)
  const [pinData,setPinData] = useState({
    pin: '',
  });
  
  const [errors, setErrors] = useState();
  
  const [availablePin,setavailablePin]=useState();
   

  const pinCodeCheck =async()=>{
    setLoader(true);
    try {
      const response = await axios.post(`/check/pin`,pinData);
      if (response.data.success == true){
        // setPinData(response.data)
        setavailablePin(response.data.message);
        setErrors(false)
        setLoader(false);
      }else{
        setLoader(false);
      } 
    } catch (error) {
      console.log(error);
      setLoader(false);
      setErrors(error.response.data.message);
      setavailablePin(false)
    }
  }

  const selectSize = (item) => {
    setSizeId(item.id);
    setDone(false)
    setSizeWisePrice(item)
  }

  const cartScreenHandler = (id) => {
    if (Auth.auth && Auth.auth != null) {
      addToCart(id)
    } else {
      navigate('/login');
    }
  }

  const appLoad = async() => {
    setLoader(true);
    if (Auth.auth && Auth.auth === true) {
      try {
        let token = decrpt(Cookies.get('s_l'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.get(`/user/app/load`)
         .then((response) => {
          if(response.data.success){
            Count.setCountWishCart(response.data);
          setLoader(false);
          }
        })
      }catch(error){
        console.log(error , 'error 1');
        setLoader(false);
      }
    } else {
      try {
        const response = await axios.get(`/user/app/load`)
         .then((response) => {
          if(response.data.success){
             setLoader(false);
          }
        })
      }catch(error){
        // console.log(error , 'error 2');
        setLoader(false);
      }
    }

  }

  const addToCart= async() => {
    setLoader(true);
    let data = {
      product_id:itemID,
      quantity:quantity,
      product_size_id:sizeId
    }
    try {
      let token = decrpt(Cookies.get('s_l'));
      // console.log('tokentoken', token)
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/user/cart/add`,data);
      console.log(response,'add to cart response');
      
      if (response.data.data) {
        console.log(response.data.data,'data data');
        setNewAddedData( response.data.data);
        setLoader(false);
        appLoad();
        let newAddedData = response.data.data;
        let cartData = Count.countWishCart && Count.countWishCart.cart;
        console.log(cartData,'cartData');
        console.log(newAddedData,'newAddedData');
        let exists = cartData.some(v => (v.product_id === newAddedData.product_id));
        if (!exists) {
            cartData.push(newAddedData);
            Count.setCountWishCart({cartData});
        }
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      } 
   else {
        setLoader(false);
      }
    } catch (error) {
      console.log('add to cart error',error);
      toast.warn(error.response.data.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
        // Auth.setAuth(false);
        // Cookies.remove('s_l');
        // navigate('/login');
      setLoader(false);
    }
  }

  const addWishList = async(id)=>{
    setLoader(true);
    try {
      let data = {
        product_id: id
    };
      console.log(data);
      let token = decrpt(Cookies.get('s_l'));
      console.log(token,'token token');
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.post(`/user/wishlist/add`,data);
      console.log(response,'response wishlist');
      if (response.data.success) {
        // Wishlist.setAddWishList(response.data.data);
        setLoader(false);
        // productDetailsfetch(itemID);
        appLoad();
        const result = productDetailsfetch(itemID);
        async function productDetailsfetch (itemID)  {
          setLoader(true);
          try{
            const response = await axios.post(`/user/product/details` , {product_id: itemID})
            .then((response) => {
              console.log(response.data,'details');
              setProductDetails(response.data.data);
              setSize(response.data.data.stock_details);
              setWishlistData(response.data.wishlist);
              // sizeCheck();
              setLoader(false);
            })
          }catch(error){
            setLoader(true);
            console.log(error , 'error');
          }
        }
        setDone(true);
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      } else {
        setLoader(false);
      }
      
    } catch (error) {
      console.log(error,'error');
      setLoader(false);
      Auth.setAuth(false);
      Cookies.remove('s_l');
      navigate('/login');
    }
  }

  const removeWishlist = async (id) => {
    setLoader(true);
    let data = {
      wishlist_id: id
    };
     
    try {
        let token = decrpt(Cookies.get('s_l'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.post(`/user/wishlist/delete`, data);
        console.log('delete response', response);
        setLoader(false);
        if (response.data.success == true) {
          const result = productDetailsfetch(itemID);
          async function productDetailsfetch (itemID)  {
            setLoader(true);
            try{
              const response = await axios.post(`/user/product/details` , {product_id: itemID})
              .then((response) => {
                console.log(response.data,'details product');
                setProductDetails(response.data.data);
                setSize(response.data.data.stock_details);
                setWishlistData(response.data.wishlist);
                appLoad();
                // sizeCheck();
                setLoader(false);
              })
            }catch(error){
              setLoader(true);
              console.log(error , 'error');
            }
          }
         } else {
            alert(response.data.message);
         
        }
    } catch (error) {
        console.log("Something Went Wrong Please Try Again", error);
        setLoader(false);
    }
}

const wishListScreenHandler = (id) => {
  if (Auth.auth && Auth.auth != null) {
    addWishList(id)
  } else {
    navigate('/login');
  }
}

let paragraph = productDetails && productDetails.description;

  useEffect(() => {
    window.scrollTo(0, 0);
    productDetailsfetch(itemID);
     sizeCheck(productDetails && productDetails.size_type);
  }, [itemID]);


  return (
    <div className="responsive-xs responsive-md-lg">
      {loader && loader ? <div className="position-loader"><Loader/></div>  : 
            <>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
              />
      {/* Produt List */}

      <section className="light-grey">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{productDetails && productDetails.name}</title>
            </Helmet>
        <div className="container">
          <div className="product-detail">
            
          <div className="page-header">
                <div className="page-title">
                  {/* <h4>{productDetails && productDetails.third_category}</h4> */}
                  <h6 >
                    <a style={{display : 'flex' , alignItems  : 'center' }} href="javascript:void(0)" className="text-decoration-none bread-items ">Home <BiChevronRight className="page-divide" /></a>
                    <a style={{display : 'flex' , alignItems  : 'center' }} href="javascript:void(0)" className="text-decoration-none bread-items ">{productDetails && productDetails.sub_category}<BiChevronRight className="page-divide" /> </a>
                    <a style={{display : 'flex' , alignItems  : 'center' }} href="javascript:void(0)" className="text-decoration-none bread-items ">{productDetails && productDetails.third_category}<BiChevronRight className="page-divide" /></a>
                    <a style={{display : 'flex' , alignItems  : 'center' }} href="javascript:void(0)" className="text-decoration-none bread-items ">{productDetails && productDetails.brand}</a>
                  </h6>
                </div>
              </div>
            <div className="product-detail-top d-flex">
            <div className="fluid react-slick">
            <div className="fluid">
                <div className="fluid__image-container">
                    {/* <ReactImageMagnify {...{
                        smallImage: {
                            alt: 'Wristwatch by Ted Baker London',
                            isFluidWidth: true,
                            src: productDetails && productDetails.main_image,
                            
                        },
                        largeImage: {
                            src: productDetails && productDetails.main_image,
                            width: 1000,
                            height: 800
                        },
                        style: {
                          border: '1px solid #ddd',
                          background: '#fff',
                          bordeRadius: '10px',
                          width: 100, height: 100
                        },
                      
                          enlargedImagePosition: 'over',
                          shouldUsePositiveSpaceLens:true,
                          isHintEnabled:true,
                          hintTextMouse:"Hover to zoom",
                          shouldHideHintAfterFirstActivation:false,
                          enlargedImageContainerStyle:{
                            background: '#fff'
                          },
                          lensStyle: { border: 'none',background: 'transparent',borderRadius:100},
                        isHintEnabled: true,
                        shouldHideHintAfterFirstActivation: false
                        
                    }} /> */}
                    {imgLoader && imgLoader ? <Loader/> :
                      <>
                          {slider && slider.length >= 1 ?
                          <SliderImage 
                          data={slider && slider} 
                          // data={data} 
                          // width="500px" 
                          height='100%'
                          showDescription={true} 
                          direction="right" 
                        />  : <>''</>
                          }
                      </>
                    }
                </div>
            </div>
            </div>
              <div className="item-detail">
                  <h5 style={{color : '#682d006e'}}>{productDetails && productDetails.brand}</h5>
                  <h2 className="item-title">{productDetails && productDetails.name} </h2>
                <h6 className="item-cat">
                  {productDetails && productDetails.category  ?
                  <p> {productDetails && productDetails.category}</p>
                  : <></>
                }
                  {productDetails && productDetails.sub_category  ?
                  <p> {productDetails && productDetails.sub_category}</p>
                  : <></>
                }
                  {productDetails && productDetails.third_category  ?
                  <p> {productDetails && productDetails.third_category}</p>
                  : <></>
                }
                </h6>
                <div className="item-short-desp">
                  <p>{productDetails && productDetails.short_description}</p>
                </div>
                <div className="item-price">
                    
                    {
                      sizeWisePrice  && sizeWisePrice !== null ?
                          <>
                            <span className="current-price" >₹ {sizeWisePrice && sizeWisePrice.price}</span>
                            <span>
                                <span className="save-price">
                                  {
                                  Math.floor(100 * ((sizeWisePrice && sizeWisePrice.mrp) - (sizeWisePrice && sizeWisePrice.price))/(sizeWisePrice && sizeWisePrice.mrp))
                                  }
                                  % Off
                                  
                                  </span>
                                <span className="old-price">₹{sizeWisePrice && sizeWisePrice.mrp}</span>
                            </span>
                          </>
                      :
                       <>
                          <span className="current-price" >₹ {productDetails && productDetails.price}</span>
                          <span>
                              <span className="save-price">{discount}% Off</span>
                              <span className="old-price">₹{productDetails && productDetails.mrp}</span>
                          </span>
                      </> 
                    }
                </div>
                {productDetails && productDetails.width  !== null && productDetails.height !== null && productDetails.weight !== null  ?
                    <div className="item-availability">
                      <h6>Size & Fit</h6>
                      <div className="d-flex gap-2">
                        {productDetails && productDetails.width !== null ?  <span style={{color: '#282c3f'}}>width :{productDetails && productDetails.width} inch </span> : ''}
                        {productDetails && productDetails.height !== null ?  <span style={{color: '#282c3f'}}>height :{productDetails && productDetails.height}inch </span> : ''}
                        {productDetails && productDetails.weight !== null ?  <span style={{color: '#282c3f'}}>Weight :{productDetails && productDetails.weight}gm </span> : ''}
                      </div>
                    </div>
                  : ''
                  }
              
                <div className="item-availability">
                  <h6>Availability</h6>
                  <div>
                    <div className="item-availability-input-btn" style={{display:'flex'}}>
                      <input type="text" className="form-control btn-hover" onChange={e=> setPinData({...pinData ,  pin:e.target.value})}/>
                      <button onClick={() => pinCodeCheck()}>Check Pincode</button>
                      <p>Check your Delivery for your Location</p>
                    </div>
                    <div className="delivery-resposive">
                    {availablePin  && (<span className="success"><FaRegCheckCircle />{availablePin}</span>)}
                    {errors && (<span className="danger"><FaRegTimesCircle /> {errors}</span>)}
                    </div>
                  </div>
                </div>
                <div className="item-quantity-size d-flex">
                   <div className="item-quantity">
                    <h6>Quantity</h6>
                    <div className="item-quantity-inner">
                      <span>
                        <button onClick={() => productQuantityChange(1)}><AiOutlineMinus /></button>
                      </span>
                      <span><input type="text" maxLength="3"  onChange={setQuantity} value={quantity} style={{width: 50,textAlign: 'center'}}/></span>
                      <span>
                        <button onClick={() => productQuantityChange(2)}><AiOutlinePlus /></button>
                      </span>
                    </div>
                  </div>
                  <div className="item-size">
                  {productDetails && productDetails.size_type==2 ?
                  <>
                    <h6>Size</h6>
                    <div className="item-size-inner d-flex">
                      {size && size.map((item) => {
                        return (
                          <label key={item.key} onClick={() => selectSize(item)}>
                          {/* <label key={item.key}> */}
                            <input type="radio" name="size" />
                            <span>{item.size}</span>
                          </label>
                        )}
                      )}
                    </div>
                    </>:''
                    }
                  </div>
                </div>
                <div style={{display:'flex',marginTop:20}}>
                  <AddToWishListBtn wishListScreenHandler={wishListScreenHandler} itemID={itemID} removeWishlist={removeWishlist} wishListData={wishListData} />
                 
                    {productDetails?.stock == 0 ?
                    <div className="item-availability"> <span style={{
                      color: 'red',
                      background: "#ff000012",
                      padding: '12px 20px',
                      marginLeft : 10
                  }}>Out Of Stock</span> </div> : <AddToCartBtn done={done} cartScreenHandler={cartScreenHandler} itemID={itemID} newAddedData={newAddedData} productDetails={productDetails} sizeType={sizeType}/>
                  }
                 
                </div>
              </div>
            </div>
            <div className="product-detail-bottom">
              <h4>Detail</h4>
              <div>
                <p> {ReactHtmlParser(paragraph)}</p>
              </div> 
            </div>
          </div>
        </div>
      </section>
            
      {/* MostSoldProductSlider */}    
      {relatedProduct && relatedProduct.length == 0 ? 
      ' ' : 
      <section className="homeProduct related-product light-grey">
        <div className="container">
          <div className="section-title">
            <h4>Related Product</h4>
          </div>
          <ProductSliderRelated itemID={itemID} relatedProduct={relatedProduct} />
        </div>
      </section>
    }

      {/* out of stock product */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Stock Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>No More Stock Available</Modal.Body>
      </Modal>

      <Modal show={showSelectSize} onHide={SelectSizeClose}>
        <Modal.Body>Please Select Size</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={SelectSizeClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Outlet />
      </>
      }
    </div>
  );
}

export default ProductDetail;
