// import React, { createContext} from "react";

//   const CountWishlistCartContext = createContext();
//   export default CountWishlistCartContext;
import React, { createContext, useState } from "react";

const CountWishlistCartContext = createContext();

export const CountWishlistCartProvider = ({ children }) => {
  const [countWishCart, setCountWishCart] = useState({ cart: 0, wishlist: 0 });

  const updateCartCount = (newCartCount) => {
    setCountWishCart((prev) => ({ ...prev, cart: newCartCount }));
  };

  return (
    <CountWishlistCartContext.Provider value={{ countWishCart, updateCartCount }}>
      {children}
    </CountWishlistCartContext.Provider>
  );
};

export default CountWishlistCartContext;
